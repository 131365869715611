import { isAstrologyHost, isDev } from './flags'

export const baseURL = isDev ? 'https://dev01.dev.corp.hint.app' : 'https://payment.hint.app'

export const userCountryLink = 'https://ip.hintw.workers.dev/'
export const analyzePhotoLink = isDev
  ? 'https://dev01.dev.corp.hint.app/palmistry/api/processing1'
  : 'https://palmistry.hint.app/api/processing'

const targetDomain = isAstrologyHost ? 'astrology' : 'hint'

export const basePurchaseUrl = isDev
  ? `https://dev.purchase.${targetDomain}.app/payment-3`
  : `https://purchase-2.${targetDomain}.app/payment-3`

export const baseOldPurchaseUrl = isDev
  ? `https://dev.purchase.${targetDomain}.app/payment-3`
  : `https://purchase.${targetDomain}.app/payment`

export const eulaLink = isAstrologyHost ? 'https://astrology.app/eula' : 'https://hint.app/eula'

export const privacyLink = isAstrologyHost ? 'https://astrology.app/privacy-notice' : 'https://hint.app/privacy-notice'

export const helpCenterLink = isAstrologyHost ? 'https://help.astrology.app/' : 'https://help.hint.app/'

export const supportLink = isAstrologyHost
  ? 'https://help.astrology.app/hc/astrology-app-help-center/articles/1696834477-get-help-from-astrology-app-support'
  : 'https://help.hint.app/hc/hint-help-center/articles/1696510342-get-help-from-hint-support'
